import Image from 'next/future/image';
import { useEffect } from 'react';
import classNames from 'classnames';

import { getEnterpriseAgencyPage, getEnterpriseAgencyPaths } from '@cms/enterpriseAgency';
import Text from '@hiredigital/ui/Text/Text';
import VideoLazyLoad from '../lib/videoLazyload';
import { skipStaticBuild } from '@helpers/utils';

import Footer from '@components/Footer/ClientFooter';
import Header from '@components/Header/AbmHeader';
import BaseLayout from '@components/Layout/BaseLayout';
import SectionContainer from '@components/Section/Container';
import Section from '@components/Section/Section';

import AgencyCardSection from '@components/Abm/AgencyCardSection';
import AgencyCaseSection from '@components/Abm/AgencyCaseSection';
import AgencyCtaSection from '@components/Abm/AgencyCtaSection';
import AgencyInsightsSection from '@components/Abm/AgencyInsightsSection';
import AgencyLearnSection from '@components/Abm/AgencyLearnSection';
import AgencyLogoSection from '@components/Abm/AgencyLogoSection';
import AgencyTalentsSection from '@components/Abm/AgencyTalentsSection';
import AgencyTestimonialSection from '@components/Abm/AgencyTestimonialSection';
import CaseMetricSection from '@components/Abm/CaseMetricSection';
import DefaultSection from '@components/Abm/DefaultSection';
import Styles from '@components/Abm/Styles.module.scss';

const isDark = (bgColor) => {
  if (!bgColor) {
    return 'initial';
  }
  // This could be css-only https://css-tricks.com/switch-font-color-for-different-backgrounds-with-css/
  var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? 'initial' : '#fff';
};

const getSection = (section, ind) => {
  switch (section.type) {
    case 'AgencyLogoSection':
      const isScheduleCallSection = !!(
        section.scheduleCallSectionSubtitle && section.scheduleCallLogoHeading
      );
      return (
        <AgencyLogoSection
          section={section}
          key={ind}
          isScheduleCallSection={isScheduleCallSection}
        />
      );
    case 'AgencyTestimonialSection':
      return <AgencyTestimonialSection section={section} key={ind} />;
    case 'AgencyCaseSection':
      return <AgencyCaseSection section={section} key={ind} />;
    case 'AgencyCardSection':
      return <AgencyCardSection section={section} key={ind} />;
    case 'AgencyTalentsSection':
      return <AgencyTalentsSection section={section} key={ind} />;
    case 'AgencyInsightsSection':
      return <AgencyInsightsSection section={section} key={ind} />;
    case 'AgencyLearnSection':
      return <AgencyLearnSection section={section} key={ind} />;
    case 'AgencyCtaSection':
      return <AgencyCtaSection section={section} key={ind} />;
    case 'CaseMetricSection':
      return <CaseMetricSection section={section} key={ind} />;
    default:
      return <DefaultSection section={section} key={ind} />;
  }
};

const EnterpriseAgencyPage = ({
  head: propHead,
  title,
  description,
  sections,
  featuredImage,
  featuredVideo,
  private: propPrivate,
  footer,
  // analyticsData,
  accentColor1,
  accentColor2,
}) => {
  useEffect(() => {
    const rootStyle = document.querySelector(':root').style;
    if (accentColor1?.hex) {
      rootStyle.setProperty('--accentColorPrimaryBackground', accentColor1?.hex);
      rootStyle.setProperty('--accentColorPrimaryText', isDark(accentColor1?.hex));
    }
    if (accentColor2?.hex) {
      rootStyle.setProperty('--accentColorSecondaryBackground', accentColor2?.hex);
      rootStyle.setProperty('--accentColorSecondaryText', isDark(accentColor2?.hex));
    }
  }, [accentColor1?.hex, accentColor2?.hex]);

  const head = propHead || {};
  if (!head.title) head.title = `${title} | Poly`;
  if (!head.meta) head.meta = [];
  if (propPrivate) {
    head.meta.push(
      { name: 'robots', content: 'noindex' },
      { name: 'description', content: 'XXXX' }
    );
  }

  return (
    <BaseLayout className={Styles.baseLayout} pageHead={head}>
      <div className={Styles.mediaIndicator}></div>
      <VideoLazyLoad />
      <Header />

      <SectionContainer className={Styles.container}>
        <Section className={Styles.heroSection}>
          <Text element='h1' className={Styles.title}>
            {title?.text || title}
          </Text>
          <Text element='p' className={Styles.description}>
            {description}
          </Text>
          {featuredVideo ? (
            <div className={Styles.featuredImageContainer}>
              <video
                className={classNames('lazy', Styles.featuredImage)}
                autoPlay
                muted
                loop
                playsInline>
                <source data-src={featuredVideo?.url} />
              </video>
            </div>
          ) : (
            <div className={Styles.featuredImageContainer}>
              <Image
                className={Styles.featuredImage}
                src={featuredImage?.url}
                width={featuredImage?.width}
                height={featuredImage?.height}
                alt={'heroImage'}
                priority
              />
            </div>
          )}
        </Section>
      </SectionContainer>

      <>{sections.map((section, index) => getSection(section, index))}</>

      <Footer footer={footer} />
    </BaseLayout>
  );
};

export default EnterpriseAgencyPage;

export const getStaticProps = async ({ params, preview }) => {
  const { page } = await getEnterpriseAgencyPage(`${params.slug?.join('/') || 'index'}`, preview);
  if (!page) {
    return {
      notFound: true,
      revalidate: 86400,
    };
  }

  return {
    props: { ...page },
    revalidate: 86400,
  };
};

export const getStaticPaths = async () => {
  if (skipStaticBuild) return { paths: [], fallback: 'blocking' };

  const { pages } = await getEnterpriseAgencyPaths();
  const paths = pages.map((page) => {
    if (page.slug === 'index')
      return {
        params: { slug: [] },
      };
    else
      return {
        params: {
          slug: page.slug.split('/'),
        },
      };
  });

  return {
    paths,
    fallback: 'blocking',
  };
};
