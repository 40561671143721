import classNames from 'classnames';

import SectionContainer from '@components/Section/Container';
import Section from '@components/Section/Section';
import Text from '@hiredigital/ui/Text/Text';
import Styles from './Styles.module.scss';

const CaseMetricSection = ({ section, ind }) => {
  return (
    <SectionContainer
      className={classNames(Styles.container, Styles.caseMetricContainer)}
      key={ind}>
      <Section>
        <Text className={Styles.sectionTitle}>{section?.title}</Text>
        <div className={Styles.caseMetricSection}>
          {section?.caseMetrics.map((data, index) => (
            <div className={Styles.caseMetric} key={index}>
              <Text className={Styles.metricValue}>{data?.value}</Text>
              <Text className={Styles.metricDescription}>{data.description}</Text>
            </div>
          ))}
        </div>
      </Section>
    </SectionContainer>
  );
};

export default CaseMetricSection;
