import classNames from 'classnames';
import Script from 'next/script';

import Button from '@components/Abm/Button';
import SectionContainer from '@components/Section/Container';
import Section from '@components/Section/Section';
import Text from '@hiredigital/ui/Text/Text';
import Styles from './Styles.module.scss';

const AgencyTalentsSection = ({ section, ind }) => {
  return (
    <SectionContainer
      className={classNames(Styles.container, Styles.talentContainer)}
      anchor='Our_People'
      key={ind}>
      <Section className={Styles.talentSection}>
        <Text className={Styles.sectionTitle}>{section?.title}</Text>
        <Text className={Styles.sectionDescription}>{section?.description}</Text>
        <div id='talentRowContainer' className={Styles.talentRowContainer} tabIndex={0}>
          {section?.talents.map((talent, index) => (
            <div className={Styles.talentRow} key={index}>
              <img
                loading='lazy'
                className={Styles.talentPicture}
                src={talent.icon.url}
                alt={talent.title}
              />
              <div className={Styles.talentHeading}>{talent.title}</div>
              <div className={Styles.talentContent}>{talent.description.text}</div>
            </div>
          ))}
        </div>
        {/* This position adjustment uses Javascript instead of CSS so that the element can be scrolled back to 0. */}
        <Script id='adjustTalentRowContainer'>{`document.getElementById('talentRowContainer').scrollLeft = 95`}</Script>
        <div className={Styles.talentButtons}>
          <Button button={section.button1} />
          <Button button={section.button2} />
        </div>
      </Section>
    </SectionContainer>
  );
};

export default AgencyTalentsSection;
