import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { submitHubspotForm } from '@components/Registration/common/helpers';
import { getEmailDomain, getNameParts, validateCorporateAndValidEmail } from '@helpers/forms';
import TextInput from '@hiredigital/ui/Input/SimpleTextInput';
import Button from './Button';
import Styles from './Styles.module.scss';
import ButtonStyles from './Button.module.scss';

const WebinarForm = ({ submit }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [processed, setProcessed] = useState(false);

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');

  const [emailValid, setEmailValid] = useState(false);
  const [corporateValid, setCorporateValid] = useState(false);

  useEffect(() => {
    setProcessed(false);
  }, [fullName, email, title]);

  useEffect(() => {
    setEmailValid(true);
    setCorporateValid(true);
  }, [email]);

  const checkEmail = () => {
    const validator = validateCorporateAndValidEmail(email);
    setEmailValid(validator.valid);
    setCorporateValid(validator.corporate);
    return validator.valid && validator.corporate;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!!email && checkEmail()) {
      setSubmitted(false);
      setLoading(true);
      const { firstName, lastName } = getNameParts(fullName);
      const domain = getEmailDomain(email);
      try {
        submitHubspotForm(
          process.env.NEXT_PUBLIC_WEBINAR_FORMID,
          window.location.href,
          document.title,
          {
            email,
            work_email: email,
            firstname: firstName,
            lastname: lastName,
            company: domain,
            jobtitle: title,
            form_type: 'Webinar',
          }
        ).then((response) => {
          if (!response) return;
          if (response instanceof Error) throw response;
          if (response.data?.inlineMessage === 'Thanks for submitting the form.') {
            setLoading(false);
            setProcessed(true);
          }
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      setSubmitted(true);
    }
  };

  const handleEmailBlur = () => {
    checkEmail();
  };

  const validateEmail = () => {
    if (submitted && !email) {
      return 'Enter your email address';
    } else if (email) {
      if (!emailValid) {
        return 'Enter a valid email address';
      } else if (!corporateValid) {
        return 'Please enter your work email address';
      }
    }
  };

  return (
    <>
      <TextInput
        className={Styles.webinarInput}
        name='fullName'
        label='Your Name'
        defaultValue={fullName}
        onKeyPress={handleKeyPress}
        onChange={(e) => setFullName(e.target.value)}
      />
      <TextInput
        className={Styles.webinarInput}
        name='email'
        label='Work Email'
        error={validateEmail()}
        defaultValue={email}
        onKeyPress={handleKeyPress}
        onBlur={handleEmailBlur}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextInput
        className={Styles.webinarInput}
        name='title'
        label='Title'
        defaultValue={title}
        onKeyPress={handleKeyPress}
        onChange={(e) => setTitle(e.target.value)}
      />
      {processed ? (
        <div className={classNames(Styles.webinarMessage)}>{submit.url}</div>
      ) : loading ? (
        <div className={classNames(ButtonStyles.button, Styles.webinarMessage)}>
          {'Submitting...'}
        </div>
      ) : (
        <Button
          className={Styles.button}
          button={{ url: '#', label: submit.label }}
          onClick={handleSubmit}
        />
      )}
    </>
  );
};

export default WebinarForm;
