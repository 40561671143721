import classNames from 'classnames';

import WebinarForm from '@components/Abm/WebinarForm';
import SectionContainer from '@components/Section/Container';
import Section from '@components/Section/Section';
import Text from '@hiredigital/ui/Text/Text';
import Styles from './Styles.module.scss';

const AgencyLearnSection = ({ section, ind }) => {
  return (
    <SectionContainer className={classNames(Styles.container, Styles.learnContainer)} key={ind}>
      <Section className={Styles.learnSection}>
        <div className={Styles.learnGrid}>
          <div>
            <Text className={Styles.sectionTitle}>{section?.title}</Text>
            <Text className={Styles.sectionSubtitle}>{section?.subtitle}</Text>
            <div
              className={Styles.sectionDescription}
              dangerouslySetInnerHTML={{ __html: section?.description?.html }}
            />
            <div className={Styles.learnRowContainer}>
              <div className={classNames(Styles.learnRow)}>
                <Text className={Styles.learnRowTitle}>{'Speakers'}</Text>
                {section?.speakers.map((speaker, index) => (
                  <div key={index}>
                    <div className={Styles.learnSpeakerName}>{speaker.name}</div>
                    <div className={Styles.learnSpeakerRole}>{speaker.role}</div>
                    <div className={Styles.learnSpeakerCompany}>{speaker.company}</div>
                    <div className={Styles.learnSpeakerBlurb}>
                      <p>{speaker.blurb}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={Styles.learnWebinar}>
            <div
              className={Styles.webinarImageContainer}
              style={
                section.webinarCardHeroImage
                  ? { backgroundImage: `url(${section.webinarCardHeroImage.url})` }
                  : {}
              }>
              <div className={Styles.webinarTitle}>{section.webinarCardTitle}</div>
            </div>
            <WebinarForm submit={section.webinarCardButton} />
            <div
              className={Styles.webinarPrivacyAgreement}
              dangerouslySetInnerHTML={{ __html: section.webinarCardFooter?.html }}
            />
          </div>
        </div>
      </Section>
    </SectionContainer>
  );
};

export default AgencyLearnSection;
