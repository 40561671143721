import classNames from 'classnames';

import Link from '@components/Link';
import SectionContainer from '@components/Section/Container';
import Section from '@components/Section/Section';
import { months } from '@helpers/utils';
import Text from '@hiredigital/ui/Text/Text';
import Styles from './Styles.module.scss';

const AgencyInsightsSection = ({ section, ind }) => {
  return (
    <SectionContainer className={classNames(Styles.container, Styles.insightContainer)} key={ind}>
      <Section className={Styles.insightSection}>
        <Text className={Styles.sectionTitle}>{section?.title}</Text>
        <div className={Styles.insightRowContainer}>
          {section?.insights.map((insight, index) => {
            const d = new Date(insight.date);
            const dF = `${d.getDate()} ${months[d.getMonth()]}'${String(d.getFullYear()).substring(
              2
            )}`;
            return (
              <Link href={insight.url} className={Styles.insightRow} key={index}>
                <div className={classNames(Styles.insightHeading, Styles[insight.type])}>
                  {insight.type} • {dF}
                </div>
                <p className={Styles.insightContent}>{insight.title}</p>
              </Link>
            );
          })}
        </div>
      </Section>
    </SectionContainer>
  );
};

export default AgencyInsightsSection;
