import SectionContainer from '@components/Section/Container';
import Section from '@components/Section/Section';
import Text from '@hiredigital/ui/Text/Text';
import Styles from './Styles.module.scss';

const DefaultSection = ({ section, ind }) => {
  return (
    <SectionContainer className={Styles.container} key={ind}>
      <Section>
        <Text className={Styles.sectionTitle}>{section.type}</Text>
      </Section>
    </SectionContainer>
  );
};

export default DefaultSection;
