import classNames from 'classnames';

import Button from '@components/Abm/Button';
import Link from '@components/Link';
import SectionContainer from '@components/Section/Container';
import Section from '@components/Section/Section';
import Text from '@hiredigital/ui/Text/Text';
import Styles from './Styles.module.scss';

const ExpertTalentItem = ({ card }) => {
  const title = card.title?.split('\n').map((e) => e.trim());
  return (
    <div className={Styles.card}>
      {card.imageOrIcon && (
        <img loading='lazy' className={Styles.cardIcon} src={card.imageOrIcon.url} alt='icon' />
      )}
      <h3 className={Styles.cardTitle}>{title[0]}</h3>
      <h4 className={Styles.cardSubtitle}>{title[1]}</h4>
      <div className={Styles.cardDesc}>
        {card.description}
        {card.logo && (
          <div className={Styles.cardLogo}>
            <img loading='lazy' src={`/logos/white-cropped/${card.logo}.svg`} alt={card.logo} />
          </div>
        )}
      </div>
    </div>
  );
};

const AgencyCardItem = ({ card }) => {
  return (
    <div className={Styles.card}>
      {card.imageOrIcon && (
        <img loading='lazy' className={Styles.cardIcon} src={card.imageOrIcon.url} alt='icon' />
      )}
      <h3 className={Styles.cardTitle}>{card.title}</h3>
      <p className={Styles.cardDesc}>
        {card.description}
        {card.linkText && (
          <Link href='https://hiredigital.com/' className={Styles.cardLink}>
            {card.linkText}
          </Link>
        )}
      </p>
      {card.sidebarTitle && (
        <div>
          <h4 className={Styles.sidebarTitle}>{card.sidebarTitle}</h4>
          <div
            className={classNames(
              Styles.sidebarList,
              card.sidebarList.length <= 7 && Styles.fewItemsOnly
            )}>
            {card.sidebarList.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const AgencyCardSection = ({ section, ind }) => {
  let style = section.cardStyle;
  let cardAnchor = style;
  switch (style) {
    case 'Expertise':
    case 'Digital_Expertise':
    case 'Solutions':
      cardAnchor = 'Our_Expertise';
      break;
    case 'Expert_Talents':
      cardAnchor = 'Our_People';
      break;
  }
  switch (style) {
    case 'Expertise':
    case 'Proposed_Team':
    case 'Expert_Talents':
    case 'Digital_Expertise':
    default:
      return (
        <SectionContainer
          className={classNames(Styles.container, Styles.cardContainer, Styles[style])}
          anchor={cardAnchor}
          key={ind}>
          <Section className={Styles.cardSection}>
            <Text className={Styles.sectionTitle}>{section?.title}</Text>
            <Text className={Styles.sectionDescription}>{section?.description}</Text>
            <div className={Styles.cardRowContainer}>
              <div className={classNames(Styles.cardRow)}>
                {section?.cards.map((card, index) =>
                  style === 'Expert_Talents' ? (
                    <ExpertTalentItem card={card} key={index} />
                  ) : (
                    <AgencyCardItem card={card} key={index} />
                  )
                )}
              </div>
            </div>
            {section?.ctaButton && (
              <div className={Styles.cardCtaButton}>
                <span>{section?.ctaText}</span>
                <Button className={Styles.button} button={section?.ctaButton} />
              </div>
            )}
          </Section>
        </SectionContainer>
      );
  }
};

export default AgencyCardSection;
