import { useRef, useState } from 'react';
import classNames from 'classnames';

import Button from '@components/Abm/Button';
import SectionContainer from '@components/Section/Container';
import Section from '@components/Section/Section';
import { Slider } from '@contentco/react-scroll-snap-slider';
import Text from '@hiredigital/ui/Text/Text';
import Styles from './Styles.module.scss';

const AgencyTestimonialSection = ({ section, ind }) => {
  const sliderRef = useRef();
  const [slideIndex, setSlideIndex] = useState(0);
  const lastSlideIndex = section?.testimonials.length - 1;

  const sliderNavigate = (e, direction) => {
    e.preventDefault();
    e.stopPropagation();
    const slider = sliderRef.current;
    switch (direction) {
      case 'prev':
        if (slideIndex === 0) slider.scrollToSlide(lastSlideIndex);
        else slider.scrollToSlide(slideIndex - 1);
        break;
      case 'next':
        if (slideIndex === lastSlideIndex) slider.scrollToSlide(0);
        else slider.scrollToSlide(slideIndex + 1);
        break;
    }
  };
  const sliderNext = (e) => sliderNavigate(e, 'next');
  const sliderPrev = (e) => sliderNavigate(e, 'prev');

  let style = section.testimonialStyle;
  switch (style) {
    case 'Ad_Landing_Schedule_Call':
      return (
        <SectionContainer
          className={classNames(Styles.container, Styles.testimonialContainer, Styles[style])}
          style={
            section.backgroundImage
              ? { backgroundImage: `url(${section.backgroundImage.url})` }
              : {}
          }
          key={ind}>
          <Section className={Styles.testimonialSection}>
            <Text className={Styles.sectionTitle}>{section?.title}</Text>
            {section?.testimonials.map((testimonial, i) => {
              if (i > 0) return; // intentional for Ad_Landing_Schedule_Call
              return (
                <div key={i} className={Styles.testimonialContent}>
                  {testimonial?.name && (
                    <div className={Styles.testimonialNameContainer}>
                      {testimonial?.photo && (
                        <img
                          className={Styles.testimonialImage}
                          src={testimonial?.photo?.url}
                          alt='Testimonial Image'
                          loading='lazy'
                        />
                      )}
                      <div className={Styles.testimonialNameContent}>
                        <Text className={Styles.testimonialName}>{testimonial?.name}</Text>
                        <Text className={Styles.testimonialRole}>{testimonial?.role}</Text>
                        <Text className={Styles.testimonialCompany}>{testimonial?.company}</Text>
                      </div>
                    </div>
                  )}

                  <Text className={Styles.testimonialTitle}>{testimonial?.quote}</Text>
                </div>
              );
            })}
            <div className={Styles.testimonialButtons}>
              <Button button={section.button1} />
              <Button button={section.button2} isSecondary={true} />
            </div>
          </Section>
        </SectionContainer>
      );
    case 'Home_Page_About_Us':
      return (
        <SectionContainer
          className={classNames(Styles.container, Styles.testimonialContainer, Styles[style])}
          key={ind}>
          <Section className={Styles.testimonialSection}>
            <Text className={Styles.sectionTitle}>{section?.title}</Text>
            <div className={Styles.testimonialGrid}>
              {section?.testimonials.map((testimonial, i) => {
                return (
                  <div key={i} className={Styles[`testimonial${i + 1}`]}>
                    {testimonial?.logo && (
                      <img
                        className={Styles.agencyLogo}
                        loading='lazy'
                        src={`/logos/white-cropped/${testimonial.logo}.svg`}
                        alt={testimonial.logo}
                      />
                    )}
                    <Text className={Styles.testimonialTitle}>{testimonial?.quote}</Text>
                    {testimonial?.name && (
                      <div className={Styles.testimonialNameContainer}>
                        {testimonial?.photo && (
                          <img
                            className={Styles.testimonialImage}
                            src={testimonial?.photo?.url}
                            alt='Testimonial Image'
                            loading='lazy'
                          />
                        )}
                        <div className={Styles.testimonialNameContent}>
                          <Text className={Styles.testimonialName}>{testimonial?.name}</Text>
                          <Text className={Styles.testimonialRole}>{testimonial?.role}</Text>
                          <Text className={Styles.testimonialCompany}>{testimonial?.company}</Text>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </Section>
        </SectionContainer>
      );
    default:
      return (
        <SectionContainer
          className={classNames(
            Styles.container,
            Styles.testimonialContainer,
            Styles.testimonialDefault
          )}
          key={ind}>
          <Section className={Styles.testimonialSection}>
            <Text className={Styles.sectionTitle}>
              {section?.title}
              <span className={Styles.testimonialArrows}>
                <Button
                  className={Styles.testimonialArrowLeft}
                  onClick={sliderPrev}
                  ariaLabel='Previous Slide'
                />
                <Button
                  className={Styles.testimonialArrowRight}
                  onClick={sliderNext}
                  ariaLabel='Next Slide'
                />
              </span>
            </Text>
            <Slider
              ref={sliderRef}
              onSlidesVisibilityChange={(i) => setSlideIndex(i)}
              slidesPerPageSettings={{
                mobileSmall: 1,
                mobileBig: 1,
                tablet: 1,
                desktop: 1,
              }}
              disableArrow>
              {section?.testimonials.map((testimonial, i) => {
                return (
                  <div key={i}>
                    <Text className={Styles.testimonialTitle}>{testimonial?.quote}</Text>

                    {testimonial?.name && (
                      <div className={Styles.testimonialNameContainer}>
                        {testimonial?.photo && (
                          <img
                            className={Styles.testimonialImage}
                            src={testimonial?.photo?.url}
                            alt='Testimonial Image'
                            loading='lazy'
                          />
                        )}
                        <div className={Styles.testimonialNameContent}>
                          <Text className={Styles.testimonialName}>{testimonial?.name}</Text>
                          <Text className={Styles.testimonialRole}>{testimonial?.role}</Text>
                          {/*<Text className={Styles.testimonialCompany}>
                            {testimonial?.company}
                          </Text>*/}
                        </div>
                        <div className={Styles.testimonialLogo}>
                          {testimonial?.logo && (
                            <img
                              className={Styles.agencyLogo}
                              loading='lazy'
                              src={`/logos/white-cropped/${testimonial.logo}.svg`}
                              alt={testimonial.logo}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </Slider>
          </Section>
        </SectionContainer>
      );
  }
};

export default AgencyTestimonialSection;
