import { useState } from 'react';
import classNames from 'classnames';
import Image from 'next/future/image';

import SectionContainer from '@components/Section/Container';
import Section from '@components/Section/Section';
import Add from '@hiredigital/ui/Icon/icons/add.inline.svg';
import Deduct from '@hiredigital/ui/Icon/icons/deduct.inline.svg';
import Text from '@hiredigital/ui/Text/Text';
import Styles from './Styles.module.scss';
import Link from '../Link';

const AgencyCaseItemTitle = ({ caseStudy }) => {
  return (
    <>
      <Text className={Styles.caseTitle}>{caseStudy?.title}</Text>
      {caseStudy?.subtitle && <div className={Styles.caseReadMore}>{caseStudy?.subtitle}</div>}
    </>
  );
};

const AgencyCaseItem = ({ caseStudy }) => {
  const [showDetails, setShowDetails] = useState(false);
  const handleToggleClick = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setShowDetails(!showDetails);
  };
  const linkContent = (
    <>
      <Image className={Styles.caseImage} src={caseStudy?.image?.url} alt='Case Study Image' fill />
      <div className={Styles.caseContentSection}>
        <AgencyCaseItemTitle caseStudy={caseStudy} />
      </div>
      <div className={Styles.caseToggle} onClick={handleToggleClick}>
        {showDetails ? <Deduct /> : <Add />}
      </div>
    </>
  );
  return (
    <div className={Styles.caseItemContainer}>
      {caseStudy?.pageCaseStudy ? (
        <Link
          className={classNames(Styles.caseItem, Styles.caseLink)}
          href={`/case-studies/${caseStudy.pageCaseStudy.slug}`}>
          {linkContent}
        </Link>
      ) : (
        <div className={Styles.caseItem}>{linkContent}</div>
      )}
      <div className={classNames(showDetails && Styles.isDetailsVisible, Styles.caseDetails)}>
        <p className={Styles.caseDescription}>{caseStudy?.description}</p>
        <div className={Styles.caseChallenge}>
          <p className={Styles.caseHeader}>{`Challenge`}</p>
          <p className={Styles.caseContent}>{caseStudy?.challenge}</p>
        </div>
        <div className={Styles.caseSolution}>
          <p className={Styles.caseHeader}>{`Solution`}</p>
          <p className={Styles.caseContent}>{caseStudy?.solution}</p>
        </div>
        <div className={Styles.caseTeamStructure}>
          <p className={Styles.caseHeader}>{`Team Structure`}</p>
          <div className={Styles.caseTeamStructureContainer}>
            {caseStudy?.teamStructure.map((team, i) => {
              return (
                <div className={Styles.caseContent} key={i}>
                  <div dangerouslySetInnerHTML={{ __html: team?.html }} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const AgencyCaseSection = ({ section, ind }) => {
  return (
    <SectionContainer
      className={classNames(Styles.container, Styles.caseContainer)}
      anchor='Case_Studies'
      key={ind}>
      <Section className={Styles.caseSection}>
        <Text className={Styles.sectionTitle}>{section?.title}</Text>
        {section?.caseStudies.map((caseStudy, i) => {
          return <AgencyCaseItem key={i} caseStudy={caseStudy} />;
        })}
      </Section>
    </SectionContainer>
  );
};

export default AgencyCaseSection;
