import classNames from 'classnames';
import Image from 'next/future/image';

import Button from '@components/Abm/Button';
import SectionContainer from '@components/Section/Container';
import Section from '@components/Section/Section';
import Text from '@hiredigital/ui/Text/Text';
import Styles from './Styles.module.scss';

const AgencyCtaSection = ({ section, ind }) => {
  return (
    <SectionContainer
      className={classNames(Styles.container, Styles.ctaContainer, Styles[section.ctaStyle])}
      key={ind}>
      <Section
        className={Styles.ctaSection}
        style={
          section.ctaStyle == 'Prefer_On_Demand' && section.image
            ? { backgroundImage: `url(${section.image.url})` }
            : {}
        }>
        <div>
          <Text className={Styles.sectionTitle}>{section?.title}</Text>
          <Text className={Styles.sectionDescription}>{section?.description}</Text>
          <div className={Styles.ctaButtons}>
            <Button button={section.button1} />
            <Button button={section.button2} />
          </div>
        </div>
        {section.ctaStyle == 'Trained_by_Experts' && (
          <div className={Styles.ctaImageContainer}>
            <Image
              className={Styles.ctaImage}
              src={section.image.url}
              width={section.image.width}
              height={section.image.height}
              alt={section.title}
            />
          </div>
        )}
      </Section>
    </SectionContainer>
  );
};

export default AgencyCtaSection;
